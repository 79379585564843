<template>
  <div class="flex flex-col items-center">
    <img
      src="https://media.giphy.com/media/11ISwbgCxEzMyY/giphy.gif"
      alt="Ticket groomed"
      class="rounded mt-4 drop-shadow"
    />
    <h3 class="text-xl font-bold text-center">Ticket groomed!</h3>
  </div>
</template>
