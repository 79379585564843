<template>
  <div class="flex flex-col md:mt-14 p-10">
    <div>
      <h1 class="text-xl font-bold">No session <span>🤔</span></h1>
    </div>
    <div class="mt-6 text-base">
      <h4>⬅ Create or join a session</h4>
    </div>
  </div>
</template>
